export const getters = {
  clients: (state) => {
    return state.clients;
  },
  phone: (state) => {
    return state.phone;
  },
  password: (state) => {
    return state.password;
  },
};
