<template>
  <div id="connexion">
    <div class="row-fluid d-flex flex-wrap align-items-stretch">
      <div
        class="
          col-12 col-lg-6
          message-bienvenue-wrapper
          bgColor-primary
          d-flex
          flex-column
          justify-content-center
          align-items-center
        "
      >
        <HomeBienvenue></HomeBienvenue>
      </div>

      <div
        class="
          col-12 col-lg-6
          flex-grow-1
          position-relative
          d-flex
          flex-column
          justify-content-center
          align-items-center
          connexion-content-wrapper
        "
      >
        <div class="lang">
          <MainLayout></MainLayout>
        </div>
        <svg
          id="account_circle-24px"
          xmlns="http://www.w3.org/2000/svg"
          width="92"
          height="92"
          viewBox="0 0 92 92"
          class="felicitation-svg"
        >
          <path
            id="Tracé_2166"
            data-name="Tracé 2166"
            d="M0,0H92V92H0Z"
            fill="none"
          />
          <g id="confetti" transform="translate(7.397 7.705)">
            <g
              id="Groupe_12813"
              data-name="Groupe 12813"
              transform="translate(0 17.747)"
            >
              <g
                id="Groupe_12812"
                data-name="Groupe 12812"
                transform="translate(0)"
              >
                <path
                  id="Tracé_2172"
                  data-name="Tracé 2172"
                  d="M52.606,143.581a1.284,1.284,0,0,0-2.415.87l.019.049c1.171,3.055,1.255,5.391.231,6.418-1.243,1.24-4.338.852-8.281-1.035a54.907,54.907,0,0,1-14.209-10.6c-10.055-10.056-14.2-19.924-11.638-22.49a3.507,3.507,0,0,1,2.9-.678,1.284,1.284,0,0,0,.249-2.555,5.911,5.911,0,0,0-4.968,1.412c-4.7,4.7,2.486,16.967,11.638,26.12a57.409,57.409,0,0,0,14.916,11.1,25.289,25.289,0,0,0,3.1,1.239l-9.12,3.458a36.334,36.334,0,0,1-22.978-28.3,1.284,1.284,0,1,0-2.537.4A38.864,38.864,0,0,0,31.559,158.2l-15.011,5.68a1.261,1.261,0,0,0-.257-.306,17.885,17.885,0,0,1-6.624-12.43l-.6-7.213a1.268,1.268,0,0,0-1.35-1.181l-.04,0A1.284,1.284,0,0,0,6.5,144.138s0,.006,0,.009l.606,7.21A20.448,20.448,0,0,0,13.9,164.881L3.194,168.935l1.925-7.9a1.284,1.284,0,0,0-2.5-.608l-2.5,10.269a1.284,1.284,0,0,0,1.7,1.506l47.5-17.971a1.2,1.2,0,0,0,.213-.134,4.987,4.987,0,0,0,2.725-1.359C53.527,151.463,54.622,148.84,52.606,143.581Z"
                  transform="translate(-0.082 -113.468)"
                  fill="#242c36"
                />
              </g>
            </g>
            <g
              id="Groupe_12815"
              data-name="Groupe 12815"
              transform="translate(36.801 42.055)"
            >
              <g id="Groupe_12814" data-name="Groupe 12814">
                <path
                  id="Tracé_2173"
                  data-name="Tracé 2173"
                  d="M259.671,266.465a42.272,42.272,0,0,0-21.749-2.112l-4.587.77a1.284,1.284,0,0,0,.424,2.531l4.585-.764a39.674,39.674,0,0,1,20.426,1.985,1.322,1.322,0,0,0,.451.076,1.284,1.284,0,0,0,.451-2.486Z"
                  transform="translate(-232.294 -263.781)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12817"
              data-name="Groupe 12817"
              transform="translate(39.341 24.207)"
            >
              <g id="Groupe_12816" data-name="Groupe 12816">
                <path
                  id="Tracé_2174"
                  data-name="Tracé 2174"
                  d="M277.526,152.729a1.284,1.284,0,0,0-1.711-.607l-26.957,12.837a1.284,1.284,0,0,0-.607,1.711h0a1.284,1.284,0,0,0,1.711.607h0l26.957-12.837A1.284,1.284,0,0,0,277.526,152.729Z"
                  transform="translate(-248.126 -151.997)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12819"
              data-name="Groupe 12819"
              transform="translate(24.626 7.684)"
            >
              <g id="Groupe_12818" data-name="Groupe 12818">
                <path
                  id="Tracé_2175"
                  data-name="Tracé 2175"
                  d="M160.963,48.916a1.284,1.284,0,0,0-2.516.511c0,.015.007.031.01.046a35.907,35.907,0,0,1-1.916,21.705l-1.866,4.479a1.284,1.284,0,0,0,.689,1.674,1.265,1.265,0,0,0,.493.1,1.284,1.284,0,0,0,1.185-.789l1.868-4.474A38.459,38.459,0,0,0,160.963,48.916Z"
                  transform="translate(-154.576 -47.887)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12821"
              data-name="Groupe 12821"
              transform="translate(66.295 45.906)"
            >
              <g id="Groupe_12820" data-name="Groupe 12820">
                <path
                  id="Tracé_2176"
                  data-name="Tracé 2176"
                  d="M419.956,288a3.851,3.851,0,1,0,3.851,3.851A3.851,3.851,0,0,0,419.956,288Zm0,5.135a1.284,1.284,0,1,1,1.284-1.284A1.284,1.284,0,0,1,419.956,293.135Z"
                  transform="translate(-416.105 -288)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12823"
              data-name="Groupe 12823"
              transform="translate(35.708 10.269)"
            >
              <g id="Groupe_12822" data-name="Groupe 12822">
                <path
                  id="Tracé_2177"
                  data-name="Tracé 2177"
                  d="M227.956,64a3.851,3.851,0,1,0,3.851,3.851A3.851,3.851,0,0,0,227.956,64Zm0,5.135a1.284,1.284,0,1,1,1.284-1.284A1.284,1.284,0,0,1,227.956,69.135Z"
                  transform="translate(-224.105 -64)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12825"
              data-name="Groupe 12825"
              transform="translate(25.513)"
            >
              <g id="Groupe_12824" data-name="Groupe 12824">
                <circle
                  id="Ellipse_15"
                  data-name="Ellipse 15"
                  cx="2.567"
                  cy="2.567"
                  r="2.567"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12827"
              data-name="Groupe 12827"
              transform="translate(62.444 6.418)"
            >
              <g id="Groupe_12826" data-name="Groupe 12826">
                <path
                  id="Tracé_2178"
                  data-name="Tracé 2178"
                  d="M393.389,40a1.284,1.284,0,0,0-1.284,1.284v1.284a1.284,1.284,0,0,0,2.567,0V41.284A1.284,1.284,0,0,0,393.389,40Z"
                  transform="translate(-392.105 -40)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12829"
              data-name="Groupe 12829"
              transform="translate(62.444)"
            >
              <g id="Groupe_12828" data-name="Groupe 12828">
                <path
                  id="Tracé_2179"
                  data-name="Tracé 2179"
                  d="M393.389,0a1.284,1.284,0,0,0-1.284,1.284V2.567a1.284,1.284,0,1,0,2.567,0V1.284A1.284,1.284,0,0,0,393.389,0Z"
                  transform="translate(-392.105)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12831"
              data-name="Groupe 12831"
              transform="translate(65.011 3.851)"
            >
              <g id="Groupe_12830" data-name="Groupe 12830">
                <path
                  id="Tracé_2180"
                  data-name="Tracé 2180"
                  d="M410.672,24h-1.284a1.284,1.284,0,1,0,0,2.567h1.284a1.284,1.284,0,0,0,0-2.567Z"
                  transform="translate(-408.105 -24)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12833"
              data-name="Groupe 12833"
              transform="translate(58.681 3.851)"
            >
              <g id="Groupe_12832" data-name="Groupe 12832">
                <path
                  id="Tracé_2181"
                  data-name="Tracé 2181"
                  d="M370.672,24h-1.284a1.284,1.284,0,1,0,0,2.567h1.284a1.284,1.284,0,0,0,0-2.567Z"
                  transform="translate(-368.105 -24)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12835"
              data-name="Groupe 12835"
              transform="translate(47.151 13.998)"
            >
              <g id="Groupe_12834" data-name="Groupe 12834">
                <path
                  id="Tracé_2182"
                  data-name="Tracé 2182"
                  d="M303.666,87.832h-6.445a1.284,1.284,0,0,0-1.284,1.284v6.445a1.284,1.284,0,0,0,1.284,1.284h6.445a1.284,1.284,0,0,0,1.284-1.284V89.116A1.284,1.284,0,0,0,303.666,87.832Zm-1.284,6.445H298.5V90.4h3.878Z"
                  transform="translate(-295.937 -87.832)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12837"
              data-name="Groupe 12837"
              transform="translate(30.582 24.228)"
            >
              <g id="Groupe_12836" data-name="Groupe 12836">
                <path
                  id="Tracé_2183"
                  data-name="Tracé 2183"
                  d="M207.145,152.511a1.284,1.284,0,0,0-1.777-.093l-12.837,11.553a1.283,1.283,0,0,0,1.718,1.908l12.837-11.553A1.284,1.284,0,0,0,207.145,152.511Z"
                  transform="translate(-192.107 -152.105)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12839"
              data-name="Groupe 12839"
              transform="translate(67.578 29.339)"
            >
              <g id="Groupe_12838" data-name="Groupe 12838">
                <path
                  id="Tracé_2184"
                  data-name="Tracé 2184"
                  d="M425.389,184a1.284,1.284,0,0,0-1.284,1.284v1.284a1.284,1.284,0,1,0,2.567,0v-1.284A1.284,1.284,0,0,0,425.389,184Z"
                  transform="translate(-424.105 -184)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12841"
              data-name="Groupe 12841"
              transform="translate(71.225 27.86)"
            >
              <g id="Groupe_12840" data-name="Groupe 12840">
                <path
                  id="Tracé_2185"
                  data-name="Tracé 2185"
                  d="M449.918,175.99l-.908-.908A1.284,1.284,0,0,0,447.2,176.9l.908.908a1.284,1.284,0,0,0,1.815-1.815Z"
                  transform="translate(-446.836 -174.722)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12843"
              data-name="Groupe 12843"
              transform="translate(72.713 24.239)"
            >
              <g id="Groupe_12842" data-name="Groupe 12842">
                <path
                  id="Tracé_2186"
                  data-name="Tracé 2186"
                  d="M458.672,152h-1.284a1.284,1.284,0,1,0,0,2.567h1.284a1.284,1.284,0,1,0,0-2.567Z"
                  transform="translate(-456.105 -152)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12845"
              data-name="Groupe 12845"
              transform="translate(71.21 19.744)"
            >
              <g id="Groupe_12844" data-name="Groupe 12844">
                <path
                  id="Tracé_2187"
                  data-name="Tracé 2187"
                  d="M449.806,124.179a1.284,1.284,0,0,0-1.784,0l-.908.908a1.284,1.284,0,0,0,.908,2.191h0a1.284,1.284,0,0,0,.908-.376l.908-.908A1.284,1.284,0,0,0,449.806,124.179Z"
                  transform="translate(-446.739 -123.819)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12847"
              data-name="Groupe 12847"
              transform="translate(67.578 17.858)"
            >
              <g id="Groupe_12846" data-name="Groupe 12846">
                <path
                  id="Tracé_2188"
                  data-name="Tracé 2188"
                  d="M425.389,112a1.284,1.284,0,0,0-1.284,1.284v1.284a1.284,1.284,0,1,0,2.567,0v-1.284A1.284,1.284,0,0,0,425.389,112Z"
                  transform="translate(-424.105 -112)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12849"
              data-name="Groupe 12849"
              transform="translate(63.055 19.744)"
            >
              <g id="Groupe_12848" data-name="Groupe 12848">
                <path
                  id="Tracé_2189"
                  data-name="Tracé 2189"
                  d="M399,125.086l-.908-.908a1.284,1.284,0,0,0-1.815,1.815l.908.908A1.284,1.284,0,0,0,399,125.086Z"
                  transform="translate(-395.915 -123.818)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12851"
              data-name="Groupe 12851"
              transform="translate(0.002 0.058)"
            >
              <g
                id="Groupe_12850"
                data-name="Groupe 12850"
                transform="translate(0)"
              >
                <path
                  id="Tracé_2190"
                  data-name="Tracé 2190"
                  d="M15.438,5.853A1.284,1.284,0,0,0,14.4,4.981l-3.768-.548L8.954,1.019a1.336,1.336,0,0,0-2.3,0L4.963,4.432,1.2,4.981A1.284,1.284,0,0,0,.482,7.169L3.207,9.826l-.642,3.752a1.284,1.284,0,0,0,1.864,1.358L7.8,13.16l3.37,1.771a1.284,1.284,0,0,0,1.863-1.353l-.642-3.752,2.727-2.657A1.283,1.283,0,0,0,15.438,5.853ZM10.109,8.459a1.284,1.284,0,0,0-.37,1.135l.318,1.855L8.4,10.572a1.284,1.284,0,0,0-1.194,0l-1.669.875.318-1.855a1.283,1.283,0,0,0-.363-1.133L4.139,7.146,6,6.875a1.283,1.283,0,0,0,.967-.7L7.8,4.488,8.63,6.173a1.284,1.284,0,0,0,.965.7l1.861.271Z"
                  transform="translate(-0.095 -0.361)"
                  fill="#f8bd25"
                />
              </g>
            </g>
            <g
              id="Groupe_12853"
              data-name="Groupe 12853"
              transform="translate(57.339 54.743)"
            >
              <g id="Groupe_12852" data-name="Groupe 12852">
                <path
                  id="Tracé_2191"
                  data-name="Tracé 2191"
                  d="M375.182,353.172h-.005l-.529-.793a19.371,19.371,0,0,0-12.889-8.335,1.284,1.284,0,0,0-.424,2.531,16.8,16.8,0,0,1,9.8,5.461A7.573,7.573,0,0,0,366,355.562a5.135,5.135,0,1,0,8.966,5.006,7.444,7.444,0,0,0,.833-3.3,6.272,6.272,0,0,1,.281,6.666,1.284,1.284,0,1,0,2.208,1.31c.012-.02.023-.039.033-.06A8.961,8.961,0,0,0,375.182,353.172Zm-2.458,6.144a2.567,2.567,0,0,1-4.5-2.481l.012-.021a4.81,4.81,0,0,1,3.476-2.262,1.306,1.306,0,0,1,.642.149C373.442,355.305,373.5,357.927,372.724,359.316Z"
                  transform="translate(-360.294 -344.031)"
                  fill="#f8bd25"
                />
              </g>
            </g>
          </g>
        </svg>

        <div class="content-right text-center">
          <h1 class="titre-form-user">{{ $t("congrats") }}</h1>
          <p
            class="description-form-user"
            v-html="$t('felicitation:descriptionForm')"
          ></p>
        </div>

        <div class="form-user-wrapper">
          <router-link to="/" class="btn-submit d-block text-center">{{
            $t("gotoConnection")
          }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import HomeBienvenue from "../HomeBienvenue/HomeBienvenue.vue";
import { API_URL } from "../../utils/Constant";
import MainLayout from "../language/MainLayout.vue";

export default {
  name: "Felicitation",
  components: {
    HomeBienvenue,
    MainLayout,
  },
  created() {
    const { id } = this.$route.params;
    document.title = this.$route.meta.title;
    axios.patch(
      `${API_URL}/api/activate_email`,
      {},
      {
        headers: {
          Authorization: `Bearer ${id}`,
        },
      }
    );
  },
};
</script>
