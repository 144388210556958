import { render, staticRenderFns } from "./DetailsSupport.vue?vue&type=template&id=d3c6a30c&scoped=true&"
import script from "./DetailsSupport.vue?vue&type=script&lang=js&"
export * from "./DetailsSupport.vue?vue&type=script&lang=js&"
import style0 from "./DetailsSupport.vue?vue&type=style&index=0&lang=css&"
import style1 from "./DetailsSupport.vue?vue&type=style&index=1&id=d3c6a30c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3c6a30c",
  null
  
)

export default component.exports