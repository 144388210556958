export const getters = {
  couplesDashboard: (state) => {
    return state.couplesDashboard;
  },
  userCouple: (state) => {
    return state.userCouple;
  },
  trendingCouple: (state) => {
    return state.trendingCouple;
  },
   explosiveCouple: (state) => {
     return state.explosiveCouple;
   },
};
